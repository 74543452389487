import { useRef } from "react";
import { Link } from "react-router-dom";
import { SIGNIN_ROUTE, SIGNUP_ROUTE } from "@constants/routes";
import logoSrc from "@assets/img/landing_logo.svg";
import leftImgBgnSrc from "@assets/img/landing_bg_left.png";
import rightImgBgnSrc from "@assets/img/landing_bg_right.png";

export const HomeHeader = () => {
  const labelMenuRef = useRef<HTMLLabelElement>(null);

  const handleMenuItem = () => {
    if (window.innerWidth <= 900) {
      labelMenuRef.current?.click();
    }
  };

  return (
    <>
      <input type="checkbox" id="landing-menu-toggle" hidden={true} />
      <header className="landing-header d-flex a-center" id="landing-header">
        <img
          src={leftImgBgnSrc}
          alt=""
          className="landing__bg-img landing__bg-img--left"
        />
        <img
          src={rightImgBgnSrc}
          alt=""
          className="landing__bg-img landing__bg-img--right"
        />
        <div className="landing-header__logo-box d-flex a-center">
          <img src={logoSrc} alt="FITEVENT" className="landing-header__logo" />
          <label
            ref={labelMenuRef}
            htmlFor="landing-menu-toggle"
            className="landing-header__menu-toggle"
          >
            <i className="icon icon-landing-burger-closed" />
            <i className="icon icon-landing-burger-opened" />
          </label>
        </div>
        <div className="landing-header__menu-container d-flex a-center">
          <menu className="landing-header__menu d-flex a-center">
            <li>
              <a href="#landing-benefits" onClick={handleMenuItem}>
                Benefits
              </a>
            </li>
            <li>
              <a href="#landing-features" onClick={handleMenuItem}>
                Features
              </a>
            </li>
            <li>
              <a href="#landing-contact-us" onClick={handleMenuItem}>
                Contact Us
              </a>
            </li>
          </menu>
          <div className="landing-header__auth-box d-flex a-center">
            <Link
              to={SIGNUP_ROUTE}
              className="landing-header__auth-btn d-flex a-center"
            >
              Sign Up
            </Link>
            <Link
              to={SIGNIN_ROUTE}
              className="landing-header__auth-btn d-flex a-center"
            >
              Sign in
            </Link>
          </div>
        </div>
      </header>
    </>
  );
};
