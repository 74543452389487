import { Routes, Route } from "react-router-dom";
import { SignIn } from "@pages/SignIn";
import { SignUp } from "@pages/SignUp";
import { Page404 } from "@pages/Page404";
import { PageMobileApp } from "@pages/PageMobileApp";
import { CodeConfirmation } from "@pages/SignIn/CodeConfirmation";
import { PasswordConfirmation } from "@pages/SignIn/PasswordConfirmation";
import { CreatePass } from "@pages/SignIn/CreatePass";
import { ListEvents } from "@pages/ListEvents";
import { CheckRoute } from "@components/CheckRoute";
import {
  protectedRoutes,
  publicRoutes,
  redirectRoutes,
} from "@services/routes";
import { ADD_EVENT, EDIT_EVENT } from "@constants/events";
import { ManageEvent } from "@pages/ManageEvent";
import { ListSalesRep } from "@pages/ListSalesRep";
import { ListDealers } from "@pages/ListDealers";
import { SelectedDealer } from "@pages/ListDealers/SelectedDealer";
import { SelectedSalesRep } from "@pages/ListSalesRep/SelectedSalesRep";
import { ListParents } from "@pages/ListParents";
import { SelectedParent } from "@pages/ListParents/SelectedParent";
import { SelectedEvent } from "@pages/ListEvents/SelectedEvent";
import { AddUser } from "@pages/AddUser";
import { ProductTypes } from "@pages/ProductTypes";
import { ListEndUserEvents } from "@pages/ListEndUserEvents";
import { Dashboard } from "@pages/Dashboard";
import { SelectedEndUserEvent } from "@pages/ListEndUserEvents/SelectedEntUserEvent";
import { ListEndUsers } from "@pages/ListEndUsers";
import { SelectedEndUser } from "@pages/ListEndUsers/SelectedEndUser";
import { TermsConditions } from "@pages/Info/TermsConditions";
import { PrivacyPolicy } from "@pages/Info/PrivacyPolicy";
import { AboutUs } from "@pages/Info/AboutUs";
import { Home } from "@pages/Home";
import { MAIN_ROUTE } from "@constants/routes";

export const Router: React.FC = () => {
  return (
    <Routes>
      {/* Public routes **/}
      <Route
        path={redirectRoutes.main.path}
        element={
          <CheckRoute Component={Home} path={redirectRoutes.main.path} />
        }
      />
      <Route
        path={publicRoutes.about_us.path}
        element={
          <CheckRoute Component={AboutUs} path={publicRoutes.about_us.path} />
        }
      />
      <Route
        path={publicRoutes.terms_and_conditions.path}
        element={
          <CheckRoute
            Component={TermsConditions}
            path={publicRoutes.terms_and_conditions.path}
          />
        }
      />
      <Route
        path={publicRoutes.privacy_policy.path}
        element={
          <CheckRoute
            Component={PrivacyPolicy}
            path={publicRoutes.privacy_policy.path}
          />
        }
      />
      <Route
        path={publicRoutes.signup.path}
        element={
          <CheckRoute Component={SignUp} path={publicRoutes.signup.path} />
        }
      />
      <Route
        path={publicRoutes.signup_code.path}
        element={
          <CheckRoute
            Component={CodeConfirmation}
            path={publicRoutes.signup_code.path}
          />
        }
      />
      <Route
        path={publicRoutes.signup_password.path}
        element={
          <CheckRoute
            Component={CreatePass}
            path={publicRoutes.signup_password.path}
          />
        }
      />
      <Route
        path={publicRoutes.forgot_password_code.path}
        element={
          <CheckRoute
            Component={CodeConfirmation}
            path={publicRoutes.forgot_password_code.path}
          />
        }
      />
      <Route
        path={publicRoutes.forgot_password_new.path}
        element={
          <CheckRoute
            Component={CreatePass}
            path={publicRoutes.forgot_password_new.path}
          />
        }
      />
      <Route
        path={publicRoutes.signin.path}
        element={
          <CheckRoute Component={SignIn} path={publicRoutes.signin.path} />
        }
      />
      <Route
        path={publicRoutes.signin_code.path}
        element={
          <CheckRoute
            Component={CodeConfirmation}
            path={publicRoutes.signin_code.path}
          />
        }
      />
      <Route
        path={publicRoutes.signin_password.path}
        element={
          <CheckRoute
            Component={PasswordConfirmation}
            path={publicRoutes.signin_password.path}
          />
        }
      />
      {/* Protected routes **/}
      <Route
        path={protectedRoutes.events_list.path}
        element={
          <CheckRoute
            Component={ListEvents}
            path={protectedRoutes.events_list.path}
            isProtected
            access={protectedRoutes.events_list.access}
          />
        }
      />
      <Route
        path={protectedRoutes.add_event.path}
        element={
          <CheckRoute
            Component={() => <ManageEvent title={ADD_EVENT} />}
            path={protectedRoutes.add_event.path}
            isProtected
            access={protectedRoutes.add_event.access}
          />
        }
      />
      <Route
        path={protectedRoutes.add_event_participants.path}
        element={
          <CheckRoute
            Component={() => <AddUser title={ADD_EVENT} />}
            path={protectedRoutes.add_event_participants.path}
            isProtected
            access={protectedRoutes.add_event_participants.access}
          />
        }
      />
      <Route
        path={protectedRoutes.edit_event.path}
        element={
          <CheckRoute
            Component={() => <ManageEvent title={EDIT_EVENT} />}
            path={protectedRoutes.edit_event.path}
            isProtected
            access={protectedRoutes.edit_event.access}
          />
        }
      />
      <Route
        path={protectedRoutes.sales_list.path}
        element={
          <CheckRoute
            Component={ListSalesRep}
            path={protectedRoutes.sales_list.path}
            isProtected
            access={protectedRoutes.sales_list.access}
          />
        }
      />
      <Route
        path={protectedRoutes.selected_sales.path}
        element={
          <CheckRoute
            Component={SelectedSalesRep}
            path={protectedRoutes.selected_sales.path}
            isProtected
            access={protectedRoutes.selected_sales.access}
          />
        }
      />
      <Route
        path={protectedRoutes.dealers_list.path}
        element={
          <CheckRoute
            Component={ListDealers}
            path={protectedRoutes.dealers_list.path}
            isProtected
            access={protectedRoutes.dealers_list.access}
          />
        }
      />
      <Route
        path={protectedRoutes.selected_dealers.path}
        element={
          <CheckRoute
            Component={SelectedDealer}
            path={protectedRoutes.selected_dealers.path}
            isProtected
            access={protectedRoutes.selected_dealers.access}
          />
        }
      />
      <Route
        path={protectedRoutes.parents_list.path}
        element={
          <CheckRoute
            Component={ListParents}
            path={protectedRoutes.parents_list.path}
            isProtected
            access={protectedRoutes.parents_list.access}
          />
        }
      />
      <Route
        path={protectedRoutes.selected_parents.path}
        element={
          <CheckRoute
            Component={SelectedParent}
            path={protectedRoutes.selected_parents.path}
            isProtected
            access={protectedRoutes.selected_parents.access}
          />
        }
      />
      <Route
        path={protectedRoutes.event_details.path}
        element={
          <CheckRoute
            Component={SelectedEvent}
            path={protectedRoutes.event_details.path}
            isProtected
            access={protectedRoutes.event_details.access}
          />
        }
      />
      <Route
        path={protectedRoutes.product_types_list.path}
        element={
          <CheckRoute
            Component={ProductTypes}
            path={protectedRoutes.product_types_list.path}
            isProtected
            access={protectedRoutes.product_types_list.access}
          />
        }
      />
      <Route
        path={protectedRoutes.end_user_measurements_list.path}
        element={
          <CheckRoute
            Component={ListEndUserEvents}
            path={protectedRoutes.end_user_measurements_list.path}
            isProtected
            access={protectedRoutes.end_user_measurements_list.access}
          />
        }
      />
      <Route
        path={protectedRoutes.dashboard.path}
        element={
          <CheckRoute
            Component={Dashboard}
            path={protectedRoutes.dashboard.path}
            isProtected
            access={protectedRoutes.dashboard.access}
          />
        }
      />
      <Route
        path={protectedRoutes.mobile_app.path}
        element={
          <CheckRoute
            Component={PageMobileApp}
            path={protectedRoutes.mobile_app.path}
            isProtected
            access={protectedRoutes.mobile_app.access}
          />
        }
      />
      <Route
        path={protectedRoutes.end_user_measurements_list.path}
        element={
          <CheckRoute
            Component={Dashboard}
            path={protectedRoutes.end_user_measurements_list.path}
            isProtected
            access={protectedRoutes.end_user_measurements_list.access}
          />
        }
      />
      <Route
        path={protectedRoutes.my_end_user_event_details.path}
        element={
          <CheckRoute
            Component={() => <SelectedEndUserEvent type="my_user" />}
            path={protectedRoutes.my_end_user_event_details.path}
            isProtected
            access={protectedRoutes.my_end_user_event_details.access}
          />
        }
      />
      <Route
        path={protectedRoutes.end_user_event_details.path}
        element={
          <CheckRoute
            Component={() => <SelectedEndUserEvent type="another_user" />}
            path={protectedRoutes.end_user_event_details.path}
            isProtected
            access={protectedRoutes.end_user_event_details.access}
          />
        }
      />
      <Route
        path={protectedRoutes.end_users_list.path}
        element={
          <CheckRoute
            Component={ListEndUsers}
            path={protectedRoutes.end_users_list.path}
            isProtected
            access={protectedRoutes.end_users_list.access}
          />
        }
      />
      <Route
        path={protectedRoutes.end_user_info.path}
        element={
          <CheckRoute
            Component={SelectedEndUser}
            path={protectedRoutes.end_user_info.path}
            isProtected
            access={protectedRoutes.end_user_info.access}
          />
        }
      />
      <Route
        path="/*"
        element={
          <CheckRoute Component={Page404} path={`${MAIN_ROUTE}*`} isProtected />
        }
      />
    </Routes>
  );
};
