import {
  HomeHeader,
  HomeBanner,
  HomeBenefits,
  HomeFeatures,
  HomeContactUs,
} from "./components";
import logoSrc from "@assets/img/landing_logo.svg";

export const Home = () => {
  return (
    <section className="landing">
      <HomeHeader />
      <HomeBanner />
      <HomeBenefits />
      <HomeFeatures />
      <HomeContactUs />
      <footer className="landing-footer d-flex a-center jc-center">
        <a href="#landing-header">
          <img src={logoSrc} alt="FITEVENT" />
        </a>
      </footer>
    </section>
  );
};
