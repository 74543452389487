import {
  ADMIN_ROLE,
  DEALER_ROLE,
  PARENT_ROLE,
  SALES_REP_ROLE,
} from "@constants/commons";
import {
  adminHeaders,
  parentHeaders,
  dealersHeaders,
  salesRepHeaders,
  endUserHeaders,
} from "@constants/headers";
import {
  ADD_EVENT_ROUTE,
  DASHBOARD_ROUTE,
  DEALERS_LIST_ROUTE,
  DEALER_INFO_ROUTE,
  EDIT_EVENT_ROUTE,
  END_USER_INFO_ROUTE,
  END_USER_LIST_ROUTE,
  MY_END_USER_MEASUREMENTS_LIST_ROUTE,
  END_USER_MEASUREMENTS_LIST_ROUTE,
  END_USER_MEASUREMENTS_ROUTE,
  EVENTS_LIST_ROUTE,
  FORGOT_PASSWORD_CODE_ROUTE,
  FORGOT_PASSWORD_NEW_ROUTE,
  MEASUREMENTS_LIST_ROUTE,
  PARENTS_LIST_ROUTE,
  PARENT_INFO_ROUTE,
  PRODUCT_TYPES_ROUTE,
  SALES_LIST_ROUTE,
  SALES_REP_INFO_ROUTE,
  SIGNIN_CODE_ROUTE,
  SIGNIN_PASSWORD_ROUTE,
  SIGNIN_ROUTE,
  SIGNUP_CODE_ROUTE,
  SIGNUP_CREATE_PASSWORD_ROUTE,
  SIGNUP_ROUTE,
  TERMS_CONDITIONS_ROUTE,
  PRIVACY_POLICY_ROUTE,
  ABOUT_US_ROUTE,
  DEFAULT_REDIRECT_ROUTE,
  MAIN_ROUTE,
  MOBILE_APP_ROUTE,
} from "@constants/routes";
import { HeaderItem } from "@models/common/app";
import {
  IProtectedRoutes,
  IPublicRoutes,
  IRedirectRoutes,
} from "@models/common/routes";
import {
  onlyAdminRole,
  onlyDealerRole,
  onlyEndUserRole,
  onlyParentRole,
  onlySalesRepRole,
  UserRole,
} from "@models/common/user";

export const redirectRoutes = {
  main: {
    id: 1,
    fullPath: `${process.env.REACT_APP_CLIENT_LINK}${MAIN_ROUTE}`,
    path: MAIN_ROUTE,
  },
} as IRedirectRoutes;

export const publicRoutes = {
  signin: {
    id: 1,
    fullPath: `${process.env.REACT_APP_CLIENT_LINK}${SIGNIN_ROUTE}`,
    path: SIGNIN_ROUTE,
    exact: false,
  },
  signin_code: {
    id: 2,
    fullPath: `${process.env.REACT_APP_CLIENT_LINK}${SIGNIN_CODE_ROUTE}`,
    path: SIGNIN_CODE_ROUTE,
    exact: false,
  },
  signin_password: {
    id: 3,
    fullPath: `${process.env.REACT_APP_CLIENT_LINK}${SIGNIN_PASSWORD_ROUTE}`,
    path: SIGNIN_PASSWORD_ROUTE,
    exact: false,
  },
  signup: {
    id: 4,
    fullPath: `${process.env.REACT_APP_CLIENT_LINK}${SIGNUP_ROUTE}`,
    path: SIGNUP_ROUTE,
    exact: false,
  },
  signup_code: {
    id: 5,
    fullPath: `${process.env.REACT_APP_CLIENT_LINK}${SIGNUP_CODE_ROUTE}`,
    path: SIGNUP_CODE_ROUTE,
    exact: false,
  },
  signup_password: {
    id: 6,
    fullPath: `${process.env.REACT_APP_CLIENT_LINK}${SIGNUP_CREATE_PASSWORD_ROUTE}`,
    path: SIGNUP_CREATE_PASSWORD_ROUTE,
    exact: false,
  },
  forgot_password_new: {
    id: 7,
    fullPath: `${process.env.REACT_APP_CLIENT_LINK}${FORGOT_PASSWORD_NEW_ROUTE}`,
    path: FORGOT_PASSWORD_NEW_ROUTE,
    exact: false,
  },
  forgot_password_code: {
    id: 8,
    fullPath: `${process.env.REACT_APP_CLIENT_LINK}${FORGOT_PASSWORD_CODE_ROUTE}`,
    path: FORGOT_PASSWORD_CODE_ROUTE,
    exact: false,
  },
  terms_and_conditions: {
    id: 9,
    fullPath: `${process.env.REACT_APP_CLIENT_LINK}${TERMS_CONDITIONS_ROUTE}`,
    path: TERMS_CONDITIONS_ROUTE,
    exact: false,
  },
  privacy_policy: {
    id: 10,
    fullPath: `${process.env.REACT_APP_CLIENT_LINK}${PRIVACY_POLICY_ROUTE}`,
    path: PRIVACY_POLICY_ROUTE,
    exact: false,
  },
  about_us: {
    id: 11,
    fullPath: `${process.env.REACT_APP_CLIENT_LINK}${ABOUT_US_ROUTE}`,
    path: ABOUT_US_ROUTE,
    exact: false,
  },
} as IPublicRoutes;

export const protectedRoutes = {
  events_list: {
    id: 1,
    fullPath: `${process.env.REACT_APP_CLIENT_LINK}${EVENTS_LIST_ROUTE}/:page`,
    path: `${EVENTS_LIST_ROUTE}/:page`,
    exact: false,
    access: [
      ...onlySalesRepRole,
      ...onlyDealerRole,
      ...onlyParentRole,
      ...onlyAdminRole,
    ],
  },
  add_event: {
    id: 2,
    fullPath: `${process.env.REACT_APP_CLIENT_LINK}${ADD_EVENT_ROUTE}`,
    path: `${ADD_EVENT_ROUTE}`,
    exact: false,
    access: [
      ...onlySalesRepRole,
      ...onlyDealerRole,
      ...onlyParentRole,
      ...onlyAdminRole,
    ],
  },
  edit_event: {
    id: 3,
    fullPath: `${process.env.REACT_APP_CLIENT_LINK}${EDIT_EVENT_ROUTE}/:eventID`,
    path: `${EDIT_EVENT_ROUTE}/:eventID`,
    exact: false,
    access: [
      ...onlySalesRepRole,
      ...onlyDealerRole,
      ...onlyParentRole,
      ...onlyAdminRole,
    ],
  },
  sales_list: {
    id: 4,
    fullPath: `${process.env.REACT_APP_CLIENT_LINK}${SALES_LIST_ROUTE}/:page`,
    path: `${SALES_LIST_ROUTE}/:page`,
    exact: false,
    access: [...onlyDealerRole, ...onlyParentRole, ...onlyAdminRole],
  },
  dashboard: {
    id: 5,
    fullPath: `${process.env.REACT_APP_CLIENT_LINK}${DASHBOARD_ROUTE}`,
    path: `${DASHBOARD_ROUTE}`,
    exact: false,
    access: [...onlyDealerRole, ...onlyParentRole, ...onlyAdminRole],
  },
  mobile_app: {
    id: 5,
    fullPath: `${process.env.REACT_APP_CLIENT_LINK}${MOBILE_APP_ROUTE}`,
    path: `${MOBILE_APP_ROUTE}`,
    exact: false,
    access: [
      ...onlyDealerRole,
      ...onlyParentRole,
      ...onlyAdminRole,
      ...onlySalesRepRole,
    ],
  },
  selected_sales: {
    id: 6,
    fullPath: `${process.env.REACT_APP_CLIENT_LINK}${SALES_REP_INFO_ROUTE}/:salesRepId/:page`,
    path: `${SALES_REP_INFO_ROUTE}/:salesRepId/:page`,
    exact: false,
    access: [...onlyDealerRole, ...onlyParentRole, ...onlyAdminRole],
  },
  dealers_list: {
    id: 7,
    fullPath: `${process.env.REACT_APP_CLIENT_LINK}${DEALERS_LIST_ROUTE}/:page`,
    path: `${DEALERS_LIST_ROUTE}/:page`,
    exact: false,
    access: [...onlyParentRole, ...onlyAdminRole],
  },
  selected_dealers: {
    id: 8,
    fullPath: `${process.env.REACT_APP_CLIENT_LINK}${DEALER_INFO_ROUTE}/:dealerId/:page`,
    path: `${DEALER_INFO_ROUTE}/:dealerId/:page`,
    exact: false,
    access: [...onlyParentRole, ...onlyAdminRole],
  },
  parents_list: {
    id: 9,
    fullPath: `${process.env.REACT_APP_CLIENT_LINK}${PARENTS_LIST_ROUTE}/:page`,
    path: `${PARENTS_LIST_ROUTE}/:page`,
    exact: false,
    access: onlyAdminRole,
  },
  selected_parents: {
    id: 10,
    fullPath: `${process.env.REACT_APP_CLIENT_LINK}${PARENT_INFO_ROUTE}/:parentId/:page`,
    path: `${PARENT_INFO_ROUTE}/:parentId/:page`,
    exact: false,
    access: onlyAdminRole,
  },
  event_details: {
    id: 11,
    fullPath: `${process.env.REACT_APP_CLIENT_LINK}${MEASUREMENTS_LIST_ROUTE}/:eventId/:page`,
    path: `${MEASUREMENTS_LIST_ROUTE}/:eventId/:page`,
    exact: false,
    access: [
      ...onlySalesRepRole,
      ...onlyDealerRole,
      ...onlyParentRole,
      ...onlyAdminRole,
    ],
  },
  add_event_participants: {
    id: 12,
    fullPath: `${process.env.REACT_APP_CLIENT_LINK}${ADD_EVENT_ROUTE}/:eventID`,
    path: `${ADD_EVENT_ROUTE}/:eventID`,
    exact: false,
    access: [
      ...onlySalesRepRole,
      ...onlyDealerRole,
      ...onlyParentRole,
      ...onlyAdminRole,
    ],
  },
  product_types_list: {
    id: 13,
    fullPath: `${process.env.REACT_APP_CLIENT_LINK}${PRODUCT_TYPES_ROUTE}/:page`,
    path: `${PRODUCT_TYPES_ROUTE}/:page`,
    exact: false,
    access: [...onlyParentRole, ...onlyAdminRole],
  },
  end_user_measurements_list: {
    id: 14,
    fullPath: `${process.env.REACT_APP_CLIENT_LINK}${END_USER_MEASUREMENTS_ROUTE}/:page`,
    path: `${END_USER_MEASUREMENTS_ROUTE}/:page`,
    exact: false,
    access: onlyEndUserRole,
  },
  end_user_event_details: {
    id: 15,
    fullPath: `${process.env.REACT_APP_CLIENT_LINK}${END_USER_MEASUREMENTS_LIST_ROUTE}/:eventId/:page`,
    path: `${END_USER_MEASUREMENTS_LIST_ROUTE}/:eventId/:page`,
    exact: false,
    access: [
      ...onlyAdminRole,
      ...onlyParentRole,
      ...onlyDealerRole,
      ...onlySalesRepRole,
    ],
  },
  my_end_user_event_details: {
    id: 16,
    fullPath: `${process.env.REACT_APP_CLIENT_LINK}${MY_END_USER_MEASUREMENTS_LIST_ROUTE}/:eventId/:page`,
    path: `${MY_END_USER_MEASUREMENTS_LIST_ROUTE}/:eventId/:page`,
    exact: false,
    access: onlyEndUserRole,
  },
  end_users_list: {
    id: 17,
    fullPath: `${process.env.REACT_APP_CLIENT_LINK}${END_USER_LIST_ROUTE}/:page`,
    path: `${END_USER_LIST_ROUTE}/:page`,
    exact: false,
    access: [
      ...onlyAdminRole,
      ...onlyParentRole,
      ...onlyDealerRole,
      ...onlySalesRepRole,
    ],
  },
  end_user_info: {
    id: 18,
    fullPath: `${process.env.REACT_APP_CLIENT_LINK}${END_USER_INFO_ROUTE}/:userId/:page`,
    path: `${END_USER_INFO_ROUTE}/:userId/:page`,
    exact: false,
    access: [
      ...onlyAdminRole,
      ...onlyParentRole,
      ...onlyDealerRole,
      ...onlySalesRepRole,
    ],
  },
} as IProtectedRoutes;

export const publicRoutesArray = Object.keys(publicRoutes).map(
  (routeName) => publicRoutes[routeName]
);

export const protectedRoutesArray = Object.keys(protectedRoutes).map(
  (routeName) => protectedRoutes[routeName]
);

export const isRouteAuthCheck = (path: string) => {
  return (
    path === SIGNIN_ROUTE ||
    path === SIGNIN_CODE_ROUTE ||
    path === SIGNIN_PASSWORD_ROUTE ||
    path === SIGNUP_ROUTE ||
    path === SIGNUP_CODE_ROUTE ||
    path === SIGNUP_CREATE_PASSWORD_ROUTE ||
    path === MAIN_ROUTE
  );
};

export const getHeadersByUserRole = (
  role: UserRole | undefined
): HeaderItem[] | undefined => {
  if (!role) return role;
  if (role === ADMIN_ROLE) return adminHeaders;
  if (role === PARENT_ROLE) return parentHeaders;
  if (role === DEALER_ROLE) return dealersHeaders;
  if (role === SALES_REP_ROLE) return salesRepHeaders;
  return endUserHeaders;
};

export const getDefaultRouteFromHeaders = (headers: HeaderItem[] | undefined) =>
  headers ? headers[0].route : DEFAULT_REDIRECT_ROUTE;
