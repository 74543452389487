export const HomeBanner = () => {
  return (
    <div className="landing-banner d-flex a-center">
      <h2 className="landing-banner__title">
        FitEvent <span>simplifying the measuring process for </span>
        uniforms or equipment
      </h2>
      <p className="landing-banner__description">
        Seamlessly manage your equipment and uniforms, schedule fitting events,
        <br />
        invite participants, and capture data
      </p>
      <a
        href="#landing-contact-us"
        className="landing-banner__contact-us-btn landing__main-btn d-flex a-center jc-center"
      >
        Contact us
      </a>
    </div>
  );
};
